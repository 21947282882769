import React from "react";
import "../styles/information.css";
export const UsCustomsPage = () => {
  const files = [
    {
      name: "US Customs Holds Exams Price Transfer Bulletin",
      link: "https://drive.google.com/file/d/1_ys0OFbga6w0rmIPbCbr_5y-y-Meh6XS/view?usp=sharing",
    },
  ];
  return (
    <div className="informationContainer">
      <h2>US Customs Price Transfer</h2>
      <table class="informationTable">
        <thead>
          <tr>
            <th>Info</th>
            <th>Files</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td>{file.name}</td>
              {file.link && (
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open
                  </a>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
