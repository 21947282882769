import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthenticationProvider } from "./components/authenticationContext.js";
import RequireLogin from "./components/requireLogin.js";
import HomePage from "./components/home.js";
import SearchForm from "./components/searchForm.js";
import CarSearchForm from "./components/carSearchForm.js";
import CompareRateForm from "./components/compareRateForm.js";
import Navigation from "./components/navigation.js";
import Footer from "./components/footer.js";

import LoginPage from "./components/login.js";

import NotFoundPage from "./components/notFound.js";
import LoadingRatesPage from "./components/detailedLoadingRates.js";
import { HazProceduresPage } from "./components/hazProcedures.js";
import { CarrierContactsPage } from "./components/carrierContacts.js";
import { FclCoLoadersPage } from "./components/fclCoLoaders.js";
import { VgmInstructionsPage } from "./components/vgmInstructions.js";
import UploadRatesPage from "./components/uploadRates.js";
import { AirFreightContactsPage } from "./components/airFreightContacts.js";
import { ReceivingWarehousesPage } from "./components/receivingWarehouses.js";

import { CentralDispatchInlandsPage } from "./components/centralDispatchInlands.js";
import { ExportLicensesPage } from "./components/exportLicenses.js";
import { UsCustomsPage } from "./components/usCustoms.js";
import { ShipToHawaiiAndMorePage } from "./components/shipToHawaiiAndMore.js";

import { RoroCarriersPage } from "./components/roroCarriers.js";
import { UsContainerDrayPage } from "./components/usContainerDray.js";
import { DocumentsRequiredForExportPage } from "./components/documentsRequiredForExport.js";
import { CarrierDemurragePage } from "./components/carrierDemurrage.js";
import { TerminalsPierPassPage } from "./components/terminalsPierPass.js";

import { BelgiumCarrierCommodityDetailsPage } from "./components/belgiumCarrierCommodityDetails.js";
import { InsuranceHhgUniriscPage } from "./components/insuranceHhgUnirisc.js";
import { CarrierUserAndPasswordsPage } from "./components/carrierUserAndPasswords.js";
import { FumigationRequestPage } from "./components/fumigationRequest.js";
import "./App.css";

function App() {
  return (
    <AuthenticationProvider>
      <Router>
        <div className="app-container">
          <Navigation />
          <Routes>
            <Route path="/" element={<RequireLogin />}>
              <Route index element={<HomePage />} />
              <Route path="rates" element={<SearchForm />} />
              <Route path="comparerates" element={<CompareRateForm />} />
              <Route path="carrates" element={<CarSearchForm />} />
              <Route path="loadingrates" element={<LoadingRatesPage />} />
              <Route path="uploadrates" element={<UploadRatesPage />} />
              <Route path="hazprocedures" element={<HazProceduresPage />} />
              <Route
                path="belgiumcarriercommodity"
                element={<BelgiumCarrierCommodityDetailsPage />}
              />
              <Route
                path="insurancehhgunirisc"
                element={<InsuranceHhgUniriscPage />}
              />
              <Route path="carriercontacts" element={<CarrierContactsPage />} />
              <Route path="fclcoloaders" element={<FclCoLoadersPage />} />
              <Route path="uscontainerdray" element={<UsContainerDrayPage />} />
              <Route
                path="receivingwarehouses"
                element={<ReceivingWarehousesPage />}
              />
              <Route path="vgminstructions" element={<VgmInstructionsPage />} />
              <Route
                path="centraldispatchinlands"
                element={<CentralDispatchInlandsPage />}
              />
              <Route
                path="terminalspierpass"
                element={<TerminalsPierPassPage />}
              />
              <Route path="exportlicenses" element={<ExportLicensesPage />} />
              <Route path="uscustoms" element={<UsCustomsPage />} />
              <Route path="rorocarriers" element={<RoroCarriersPage />} />
              <Route
                path="airfreightcontacts"
                element={<AirFreightContactsPage />}
              ></Route>

              <Route
                path="documentsrequiredexport"
                element={<DocumentsRequiredForExportPage />}
              />
              <Route
                path="shiphawaiiandmore"
                element={<ShipToHawaiiAndMorePage />}
              />

              <Route
                path="carrierdemurrage"
                element={<CarrierDemurragePage />}
              ></Route>
              <Route
                path="carrieruserandpasswords"
                element={<CarrierUserAndPasswordsPage />}
              />
              <Route
                path="fumigationrequest"
                element={<FumigationRequestPage />}
              />
            </Route>

            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthenticationProvider>
  );
}

export default App;
