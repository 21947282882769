import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../styles/documentsRequiredForExport.css"; // Importing the CSS file
export const BelgiumCarrierCommodityDetailsPage = () => {
  const content = [
    {
      title: <b>Shipment Notice to Antwerp</b>,
      details: [
        "When shipping to Antwerp, ensure all carriers, customers, and documents are properly handled to avoid issues and additional charges.",
        "The house bill of lading and master bill of lading must contain complete and accurate details of the vehicle. Abbreviations are not acceptable.",
        "Examples of acceptable descriptions: '1975 Ford Mustang Convertible VIN# 123456789', '1964 Volkswagen Bug Convertible VIN# 123456789'.",
        <b>*Unacceptable descriptions:*</b>,
        "1975 Ford Conv VIN# 123456789 (wrong).",
        "1964 Volks Conv VIN# 123456789 (wrong).",
        "Ensure AES filings match the original title when clearing US customs to prevent rejection.",
      ],
    },
  ];
  const files = [
    {
      name: "Shipment Guidelines Document",
      link: "https://drive.google.com/file/d/1VpeTABkAEGQ9ZhwdETgiTnjkCET0yRZb/view",
    },
  ];

  const [activeSections, setActiveSections] = useState([true]);

  const toggleAccordion = (index) => {
    setActiveSections((prevActiveSections) => {
      const newActiveSections = [...prevActiveSections];
      newActiveSections[index] = !newActiveSections[index];
      return newActiveSections;
    });
  };

  return (
    <div className="container">
      <h1>Belgium Carrier Commodity Details</h1>
      <p>
        Please review the following shipment notice and guidelines for shipping
        to Antwerp. Ensure that all bill of lading entries are complete and
        compliant with US customs and carrier requirements.
      </p>

      {content.map((section, index) => (
        <div key={index} className="accordion-item">
          <button
            onClick={() => toggleAccordion(index)}
            className={`accordion-title ${
              activeSections[index] ? "active" : ""
            }`}
            aria-expanded={activeSections[index]}
          >
            {section.title}
            <span className="arrow">
              {activeSections[index] ? (
                <RiArrowDropUpLine size={24} />
              ) : (
                <RiArrowDropDownLine size={24} />
              )}
            </span>
          </button>
          {activeSections[index] && (
            <div className="accordion-content">
              {section.details.map((item, idx) => (
                <div key={idx} className="accordion-content-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="documentsInformationContainer">
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
