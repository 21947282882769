import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../styles/documentsRequiredForExport.css"; // Importing the CSS file

export const FumigationRequestPage = () => {
  const files = [
    {
      name: "Exports Rate Sheet",
      link: "https://drive.google.com/file/d/1jK1U_Pz4i-xIdtpiDUmb8uVBKXuWBU4B/view?usp=sharing",
    },
    {
      name: "BSMB Consignment Suitability",
      link: "https://drive.google.com/file/d/1HroC_nXLKkBo2pI-HwWFrlz7FWB5BWuU/view?usp=sharing",
    },
    {
      name: "BMSB Sulfuryl Fluoride Fumigation Compliance Requirements",
      link: "https://drive.google.com/file/d/1-NFgpxPLBtc87oo0wAXnC3kWZvejvhlk/view?usp=sharing",
    },
    {
      name: "Export Fumigation Request",
      link: "https://drive.google.com/file/d/1rYvTKltcyt4fWMCJRBSPrtNEEy1dDGJn/view?usp=sharing",
    },
    {
      name: "Examples of Commercial Wrap",
      link: "https://drive.google.com/drive/folders/1UdxV-xfHKxO5e8OqRLY5f7G6-YHMg9kZ?usp=sharing",
    },
    {
      name: "Example of Acceptable Plastic Wrap",
      link: "https://drive.google.com/file/d/1YD0M6jX7TAuxtxs8GR2U9wNixCZeuWT7/view?usp=sharing",
    },
    {
      name: "Example of Not Acceptable Plastic Wrap",
      link: "https://drive.google.com/file/d/1W1dWBJT3V_x_fZs4C4ctF9my1zYfKipR/view?usp=sharing",
    },
  ];

  const sections = [
    {
      title: <b>Fumigation Details</b>,
      content: [
        "GPM FUMIGATION",
        "Jazlyn Herrera",
        "Exports Department",
        <a href="mailto:exports@gpm-inc.com">exports@gpm-inc.com</a>,
        "GPM, Inc. | 1409 S Sportsman Drive | Compton, CA 90221",
        "Office: (562)997-9270 | Ext. 107 | www.gpm-inc.com",
        "Hours of operation: Monday – Friday 8:00am to 4:00pm",
      ],
    },
    {
      title: <b>Important Notes</b>,
      content: [
        "*BMSB CERTIFICATES WILL BE SUBMITTED WITHIN 72 HRS. AFTER THE FINAL CERTIFICATE IS SENT*",
        "All import orders are to be sent to imports@gpm-inc.com",
        "All export orders are to be sent to exports@gpm-inc.com",
        "***TRACKER # IS NEEDED FOR ALL DELIVERIES / PICK UPS***",
        "**Storage charge $65 per day, Plug in charge $200 per day**",
      ],
    },
  ];

  const [activeSections, setActiveSections] = useState(
    sections.map(() => true)
  );
  const toggleAccordion = (index) => {
    setActiveSections((prevActiveSections) => {
      const newActiveSections = [...prevActiveSections];
      newActiveSections[index] = !newActiveSections[index];
      return newActiveSections;
    });
  };

  return (
    <div className="container">
      <h1>Fumigation Request</h1>
      {sections.map((section, index) => (
        <div key={index} className="accordion-item">
          <button
            onClick={() => toggleAccordion(index)}
            className={`accordion-title ${
              activeSections[index] ? "active" : ""
            }`}
            aria-expanded={activeSections[index]}
          >
            {section.title}
            <span className="arrow">
              {activeSections[index] ? (
                <RiArrowDropUpLine size={24} />
              ) : (
                <RiArrowDropDownLine size={24} />
              )}
            </span>
          </button>
          {activeSections[index] && (
            <div className="accordion-content">
              {section.content.map((item, idx) => (
                <div key={idx} className="accordion-content-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="documentsInformationContainer">
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                {file.link && (
                  <td>
                    <a
                      href={file.link}
                      target="_blank"
                      className="websiteLink"
                      rel="noopener noreferrer"
                    >
                      Click here to open
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
