import React from "react";
import "../styles/information.css";
export const RoroCarriersPage = () => {
  const carriers = [
    {
      location: "NYK RORO",
      email: "",
      tel: "713-670-7110",
      description: "Services and Origins",
    },
    {
      location: "NYK RORO",
      email: "lynette.twombly@nykgroup.com",
      tel: "630-435-7880",
      description: "",
    },
    {
      location: "NYK RORO",
      email: "alicia.lopez@nykgroup.com",
      tel: "410-534-4365",
      description: "",
    },
    {
      location: "NYK RORO",
      email: "john.edel@nykgroup.com",
      tel: "",
      description: "",
    },
    {
      location: "Port Hueneme/California Customer Service",
      email: "alicia.lopez@nykgroup.com",
      tel: "630-435-7835",
      description: "",
    },
    {
      location: "Wallenius Wilhelmsen Logistics",
      email: "Pricingamericas@2wglobal.com",
      tel: "201-505-4000",
      description: "LAX/ NY / HOU / SAV / BAL / NY",
    },
    {
      location: "K-Line America",
      email: "KAM-RORO-Sales@us.kline.com",
      tel: "(804) 560-3640",
      description: "LAX / HOU / SAV / BAL / NY",
    },
    {
      location: "Höegh Autoliners Inc.",
      email: "RateRequest.US@hoegh.Com",
      tel: "(800) 285-4585",
      description: "HOU / SAV / BAL / NY",
    },
    {
      location: "EUKOR Car Carriers",
      email: "rates.us@eukor.com",
      tel: "251-461-2731",
      description: "HOU / SAV / BAL /NY",
    },
    {
      location: "Freightplus (USA) INC",
      email: "Logan.Mihuta@freightplus.com",
      tel: "+1 678 369 5415",
      description: "RORO / COLOADER",
    },
    {
      location: "shipoverseas",
      email: "jack@shipoverseas.com",
      tel: "858 547 0840",
      description: "LAX / LGB / HOU / SAV / BAL /NY",
    },
    {
      location: "shipoverseas",
      email: "ricardo@shipoverseas.com",
      tel: "",
      description: "roro coloader",
    },
    {
      location: "shipoverseas",
      email: "kendra@shipoverseas.com",
      tel: "",
      description: "",
    },
    {
      location: "shipoverseas",
      email: "",
      tel: "888 805 4994",
      description: "",
    },
    {
      location: "Transglobal TEXAS",
      email: "aldof@tgal.us",
      tel: "972-602-1670 Ext: 1201",
      description: "LAX / LGB / HOU / SAV / BAL /NY",
    },
    {
      location: "Transglobal TEXAS",
      email: "alfredg@oceansinternational.us",
      tel: "949 212 0609",
      description: "roro coloader",
    },
    {
      location: "Transglobal TEXAS",
      email: "matts@oceansinternational.us",
      tel: "843 714 7537",
      description: "roro coloader",
    },
    {
      location: "Transglobal TEXAS",
      email: "RayA@tgal.us",
      tel: "972-602-1670 Ext: 1215",
      description: "",
    },
    {
      location: <b>CARS FROM JAPAN</b>,
      email: "",
      tel: "",
      description: "CARS FROM JAPAN",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "",
      tel: "",
      description: "LAX",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "ANTON.LIZARES@MOLGROUP.COM",
      tel: "562 884 1596",
      description: "FOR RATES & SERVICES",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "KAORU.WADA@MOLGROUP.COM",
      tel: "562 708 6745",
      description: "",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "FRIEDA.WANG@MOLGROUP.COM",
      tel: "562 544 3224",
      description: "FOR BOOKINGS & CUSTOMER SERVICE",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "EIKO.KUMASHIRO@MOLGROUP.COM",
      tel: "562 544 0197",
      description: "",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "BRIANA.ARREDONDO@MOLGROUP.COM",
      tel: "310 408 6311",
      description: "",
    },
    {
      location: "NISSIN INTL - MOL",
      email: "VALERIE.GASCON@MOLGROUP.COM",
      tel: "562 673 7663",
      description: "",
    },
  ];

  return (
    <div className="extraShippingInformationContainer">
      <h2>Roro Carriers</h2>
      <div className="extraShippingInformationTable">
        <table>
          <thead>
            <tr>
              <th>Location</th>
              <th>Email</th>
              <th>Tel</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {carriers.map((carrier, index) => (
              <tr key={index}>
                <td>{carrier.location}</td>
                <td>{carrier.email}</td>
                <td>{carrier.tel}</td>
                <td>{carrier.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
